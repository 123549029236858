// Generated by Framer (16060ad)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import Tab from "https://framerusercontent.com/modules/M42MM5QoUdAgIsIpdSJx/tzXSeR7bB4rZke2ocz0Z/VAMUDsO6f.js";
const TabFonts = getFonts(Tab);

const cycleOrder = ["QNtV7NInF", "v9XGPYXlW", "Plb7ecmaR"];

const variantClassNames = {Plb7ecmaR: "framer-v-54arme", QNtV7NInF: "framer-v-e4j616", v9XGPYXlW: "framer-v-1wfr5ac"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Tab 1": "QNtV7NInF", "Tab 2": "v9XGPYXlW", "Tab 3": "Plb7ecmaR"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tab1?: string; tab2?: string; tab3?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "QNtV7NInF", tab1: MSGp_zYf0 = "Tab 1", tab2: f72tcvvi2 = "Tab 2", tab3: GuQmBQnFI = "Tab 3", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "QNtV7NInF", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const tap162djpo = activeVariantCallback(async (...args) => {
setVariant("QNtV7NInF")
})

const tapr0bu3s = activeVariantCallback(async (...args) => {
setVariant("v9XGPYXlW")
})

const tap2m2a5e = activeVariantCallback(async (...args) => {
setVariant("Plb7ecmaR")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-UbuON", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-e4j616", className)} data-framer-name={"Tab 1"} layoutDependency={layoutDependency} layoutId={"QNtV7NInF"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({Plb7ecmaR: {"data-framer-name": "Tab 3"}, v9XGPYXlW: {"data-framer-name": "Tab 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1xxq08t-container"} layoutDependency={layoutDependency} layoutId={"qVIgWJDbJ-container"} transition={transition}><Tab height={"100%"} id={"qVIgWJDbJ"} layoutId={"qVIgWJDbJ"} title={MSGp_zYf0} variant={"Ho9BqSUe0"} width={"100%"} {...addPropertyOverrides({Plb7ecmaR: {tap: tap162djpo, variant: "ym0P2kopY"}, v9XGPYXlW: {tap: tap162djpo, variant: "ym0P2kopY"}}, baseVariant, gestureVariant)}/></motion.div><motion.div className={"framer-nne61v-container"} layoutDependency={layoutDependency} layoutId={"tpXdcB9aU-container"} transition={transition}><Tab height={"100%"} id={"tpXdcB9aU"} layoutId={"tpXdcB9aU"} tap={tapr0bu3s} title={f72tcvvi2} variant={"ym0P2kopY"} width={"100%"} {...addPropertyOverrides({v9XGPYXlW: {variant: "Ho9BqSUe0"}}, baseVariant, gestureVariant)}/></motion.div><motion.div className={"framer-sioffb-container"} layoutDependency={layoutDependency} layoutId={"IHef9gZ5k-container"} transition={transition}><Tab height={"100%"} id={"IHef9gZ5k"} layoutId={"IHef9gZ5k"} tap={tap2m2a5e} title={GuQmBQnFI} variant={"ym0P2kopY"} width={"100%"} {...addPropertyOverrides({Plb7ecmaR: {variant: "Ho9BqSUe0"}}, baseVariant, gestureVariant)}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-UbuON [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-UbuON .framer-lcxks8 { display: block; }", ".framer-UbuON .framer-e4j616 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 12px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-UbuON .framer-1xxq08t-container, .framer-UbuON .framer-nne61v-container, .framer-UbuON .framer-sioffb-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-UbuON .framer-e4j616 { gap: 0px; } .framer-UbuON .framer-e4j616 > * { margin: 0px; margin-left: calc(12px / 2); margin-right: calc(12px / 2); } .framer-UbuON .framer-e4j616 > :first-child { margin-left: 0px; } .framer-UbuON .framer-e4j616 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 49
 * @framerIntrinsicWidth 275
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"v9XGPYXlW":{"layout":["auto","auto"]},"Plb7ecmaR":{"layout":["auto","auto"]}}}
 * @framerVariables {"MSGp_zYf0":"tab1","f72tcvvi2":"tab2","GuQmBQnFI":"tab3"}
 */
const Framerz9LhxCR08: React.ComponentType<Props> = withCSS(Component, css, "framer-UbuON") as typeof Component;
export default Framerz9LhxCR08;

Framerz9LhxCR08.displayName = "Elements/Tabs Copy";

Framerz9LhxCR08.defaultProps = {height: 49, width: 275};

addPropertyControls(Framerz9LhxCR08, {variant: {options: ["QNtV7NInF", "v9XGPYXlW", "Plb7ecmaR"], optionTitles: ["Tab 1", "Tab 2", "Tab 3"], title: "Variant", type: ControlType.Enum}, MSGp_zYf0: {defaultValue: "Tab 1", displayTextArea: false, title: "Tab 1", type: ControlType.String}, f72tcvvi2: {defaultValue: "Tab 2", displayTextArea: false, title: "Tab 2", type: ControlType.String}, GuQmBQnFI: {defaultValue: "Tab 3", displayTextArea: false, title: "Tab 3", type: ControlType.String}} as any)

addFonts(Framerz9LhxCR08, [...TabFonts])